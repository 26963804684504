<template>
  <div flat class="overflow-hidden">
    <v-app-bar elevation="1" absolute color="white" dark scroll-target="#scrolling-techniques-8">
      <v-btn small icon color="secondary" @click="IrALista()">
        <v-icon>{{ icons.mdiArrowLeftThick }}</v-icon>
      </v-btn>

      <v-toolbar-title class="black--text"
        >Compra {{ datos.id <= 0 ? 'Nuevo' : datos.numero_documento }}</v-toolbar-title
      >

      <v-spacer></v-spacer>

      <v-btn
        small
        class="me-2"
        v-if="datos.pagos && datos.pagos.length > 0"
        icon
        color="secundary"
        @click="ResetPagos()"
      >
        <v-icon>{{ icons.mdiCached }}</v-icon>
      </v-btn>

      <modal-pagos
        class="me-2"
        :valorMaximo="datos.total"
        :disabled="datos.total == 0"
        :indAbono="true"
        :colorBtn="`info`"
        :TxtoBtn="'Registrar Pago'"
        :monedas="monedas"
        :pagos="datos.pagos"
        @GetPagos="GetPagos"
      >
      </modal-pagos>
      <v-btn small color="secondary" class="me-3" @click="limpiar()"> Limpiar </v-btn>
      <v-btn small color="primary" class="me-2" @click="Guardar()">
        {{ datos.fecha_recepcion == null ? 'Guardar pendiente por recibir' : 'Guardar y Recibir' }}
      </v-btn>
    </v-app-bar>
    <v-sheet id="scrolling-techniques-8" class="mx-0 px-0 overflow-y-auto" max-height="calc(100vh - 76px)">
      <div style="margin-top: 70px">
        <v-form ref="form" lazy-validation>
          <v-card-text class="d-flex">
            <v-row>
              <v-col cols="12">
                <v-row>
                  <v-col cols="12" lg="6">
                    <v-row>
                      <v-col cols="12">
                        <BuscarProveedor
                          ref="BuscarProveedorRef"
                          :label="`Proveedor`"
                          @GetProveedor="GetProveedor"
                          :rules="reglas.requerido"
                        />
                      </v-col>
                      <v-col cols="12">
                        <DatePicker
                          v-model="datos.fecha_factura"
                          :dense="true"
                          :outlined="true"
                          :label="'Fecha Factura'"
                          :clearable="true" 
                           :rules="reglas.requerido"
                        />
                      </v-col>
                      <v-col cols="12">
                        <DatePicker
                          v-model="datos.fecha_recepcion"
                          :dense="true"
                          :outlined="true"
                          :label="'Fecha Recepcion'"
                          :clearable="true"
                          :bloquearDiasProximos="true"
                        />
                      </v-col>
                      <v-col cols="12">
                        <MonedaSelect
                          :dense="true"
                          :outlined="true"
                          :add="false"
                          v-model="datos.moneda"
                          :rules="reglas.requerido"
                          :filtros="{ ind_pago_compras: true }"
                        />
                      </v-col>
                      <v-col cols="12"    v-show="datos.moneda && !datos.moneda.actual">
                        <v-row>
                          <v-col>
                            <v-text-field
                           
                              v-model="datos.tasa"
                              label="Tasa"
                              required
                              outlined
                              dense
                              hide-details
                            ></v-text-field>
                          </v-col>
                          <v-col cols="auto" v-if="datos.moneda">
                            <v-checkbox v-model="checkTasa" :label="`Mantener ${datos.moneda.simbolo}`"></v-checkbox>
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-col cols="12">
                        <v-textarea
                          v-model="datos.observacion"
                          rows="2"
                          label="Observaciones"
                          dense
                          outlined
                        ></v-textarea>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="12" lg="6">
                    <v-row>
                      <v-col cols="12">
                        <SelectGrupoNegocio
                          v-model="datos.id_grupo_negocio"
                          :add="false"
                          :dense="true"
                          :outlined="true"
                          :clearable="true"
                          :ind_principal="true"
                          :storePuesto="true"
                        />
                      </v-col>
                      <v-col cols="12">
                        <select-puesto
                          v-model="datos.id_puesto"
                          :add="false"
                          :dense="true"
                          :outlined="true"
                          :clearable="true"
                          :ind_principal="true"
                          :storePuesto="true"
                          :filtros="{ id_grupo_negocio: datos.id_grupo_negocio, modulos: '11' }"
                        ></select-puesto>
                      </v-col>

                      <v-col cols="12">
                        <SelectAlmacen
                          :dense="true"
                          :outlined="true"
                          :add="false"
                          v-model="datos.id_almacen"
                          :filtro="{ id_puesto: datos.id_puesto }"
                          :rules="reglas.requerido"
                        />
                      </v-col>
                      <v-col cols="12">
                        <v-text-field
                          v-model="datos.numero_factura"
                          label="Numero Factura"
                          required
                          outlined
                          dense
                          hide-details
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <v-text-field
                          v-model="datos.numero_control"
                          label="Numero Control"
                          required
                          outlined
                          dense
                          hide-details
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-text>
        </v-form>
        <v-divider></v-divider>
        <v-card-subtitle> Detalle </v-card-subtitle>

        <v-divider></v-divider>

        <v-card-text>
          <v-form ref="formDetalle" lazy-validation>
            <small class="error--text" v-if="!(datos.productos.length > 0)">Debe agregar por lo menos 1 producto</small>

            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th v-for="(item, index) in header.filter(it => it.visible == true)" :key="index">
                      {{ item.nombre }}
                    </th>

                    <th class="text-left">
                      <v-menu v-model="menu" :close-on-content-click="false" :nudge-width="200" offset-x>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn x-small color="secondary" v-bind="attrs" v-on="on" icon>
                            <v-icon>{{ icons.mdiTune }}</v-icon>
                          </v-btn>
                        </template>
                        <v-card>
                          <v-list>
                            <v-list-item-group style="height: 200px; overflow-y: auto">
                              <template v-for="(item, i) in header">
                                <v-list-item
                                  :key="`item-${i}`"
                                  :value="item"
                                  :active-class="item.visible ? 'primary--text' : ''"
                                >
                                  <v-list-item-content>
                                    <v-list-item-title v-text="item.nombre"></v-list-item-title>
                                  </v-list-item-content>
                                  <v-list-item-action>
                                    <v-checkbox v-model="item.visible" color="primary"> </v-checkbox>
                                  </v-list-item-action>
                                </v-list-item>
                              </template>
                            </v-list-item-group>
                          </v-list>
                        </v-card>
                      </v-menu>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, index) in datos.productos" :key="index">
                    <td v-show="header.find(it => it.nombre == 'Producto' && it.visible == true)">
                      <BuscarProducto2
                        @change="changeProducto(item)"
                        :dense="true"
                        v-model="item.producto"
                        :label="''"
                        :filtros="{ ind_compra: true }"
                        :rules="reglas.requerido"
                      />
                    </td>
                    <td v-show="header.find(it => it.nombre == 'Caja' && it.visible == true)">
                      <tr>
                        <td>
                          <v-text-field
                            flat
                            solo
                            class="custom-text-field"
                            align-text="right"
                            @keyup="changeCalcular('caja', item)"
                            v-model="item.cajas_unidad"
                             
                            type="number"
                            hide-details
                          ></v-text-field>
                        </td>
                        <td>
                          <v-text-field
                            flat
                            solo
                            class="custom-text-field"
                            align-text="right"
                            @keyup="changeCalcular('caja', item)"
                            v-model="item.cajas"
                           
                            type="number"
                            hide-details
                          ></v-text-field>
                        </td>
                      </tr>
                    </td>
                    <td v-show="header.find(it => it.nombre == 'Cantidad' && it.visible == true)">
                      <v-text-field
                        flat
                        solo
                        class="custom-text-field"
                        style="text-align: right"
                        @keyup="changeCalcular('cantidad', item)"
                        v-model="item.cantidad"
                        :suffix="item.producto ? (item.producto.id ? item.producto.unidad_medida.unidad : '') : ''"
                        
                        type="number"
                        hide-details
                        @focus="$event.target.select()"
                      ></v-text-field>
                    </td>
                    <td v-show="header.find(it => it.nombre == 'Costo Und' && it.visible == true)">
                       
                    <v-text-field
                        flat
                        solo
                        class="custom-text-field"
                        style="text-align: right"
                        v-model="item.costo_unidad_moneda"
                        @keyup="changeCalcular('costo', item)"
                        :rules="reglas.numerico_requerido"
                        :suffix="monedaSimbolo()"
                        type="number"
                        hide-details
                        @focus="$event.target.select()"
                        @change="item.costo_unidad_moneda = Number(item.costo_unidad_moneda).toFixed(4)"
                      >
                        <template
                          v-slot:append-outer
                          v-if="
                            item.costo_unidad > 0 &&
                            item.producto &&
                            item.producto.ult_compra &&
                            item.producto.ult_compra.costo_unidad > 0
                          "
                        >
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                v-if="item.producto.ult_compra.costo_unidad < (item.costo_unidad)"
                                small
                                color="error"
                                v-bind="attrs"
                                v-on="on"
                                >{{ icons.mdiArrowUpThick }}
                              </v-icon>
                              <v-icon
                                v-else-if="item.producto.ult_compra.costo_unidad == (item.costo_unidad)"
                                small
                                color="info"
                                v-bind="attrs"
                                v-on="on"
                                >{{ icons.mdiEqual }}
                              </v-icon>
                              <v-icon v-else small color="success" v-bind="attrs" v-on="on"
                                >{{ icons.mdiArrowDownThick }}
                              </v-icon>
                            </template>

                            <span v-if="item.producto.ult_compra.costo_unidad < (item.costo_unidad)">
                              <p class="mb-1">
                                Aumento segun la ultima compra un
                                {{
                                  (
                                    ((item.costo_unidad) / item.producto.ult_compra.costo_unidad - 1) *
                                    100
                                  ).toFixed(0)
                                }}%
                              </p>
                              <p class="mb-0">Actual: {{ item.costo_unidad }}$</p>
                              <p class="mb-0">Ultimo costo: {{ item.producto.ult_compra.costo_unidad }}$</p>
                              <p class="mb-0">Ultimo Proveedor: {{ item.producto.ult_compra.nombre }}</p>
                            </span>
                            <span
                              v-else-if="item.producto.ult_compra.costo_unidad == (item.costo_unidad)"
                            >
                              <p class="mb-1">Se mantuvo igual a la compra anterior</p>
                              <p class="mb-0">Actual: {{ item.costo_unidad}}$</p>
                              <p class="mb-0">Ultimo costo: {{ item.producto.ult_compra.costo_unidad }}$</p>
                              <p class="mb-0">Ultimo Proveedor: {{ item.producto.ult_compra.nombre }}</p>
                            </span>
                            <span v-else>
                              <p class="mb-1">
                                Bajo segun la ultima compra un
                                {{
                                  (
                                    ((item.costo_unidad) / item.producto.ult_compra.costo_unidad - 1) *
                                    100 *
                                    -1
                                  ).toFixed(0)
                                }}%
                              </p>
                              <p class="mb-0">Actual: {{ item.costo_unidad }}$</p>
                              <p class="mb-0">Ultimo costo: {{ item.producto.ult_compra.costo_unidad }} $</p>
                              <p class="mb-0">Ultimo Proveedor: {{ item.producto.ult_compra.nombre }}</p>
                            </span>
                          </v-tooltip>
                        </template>
                      </v-text-field>
                    </td>
                    <td v-show="header.find(it => it.nombre == 'Otro Impuesto' && it.visible == true)">
                      <v-text-field
                        flat
                        solo
                        class="custom-text-field"
                        @keyup="changeCalcular('impuesto', item)"
                        
                        v-model="item.impuesto_moneda"
                        :suffix="monedaSimbolo()"
                        type="number"
                        hide-details
                        @focus="$event.target.select()"
                        @change="item.impuesto_moneda = Number(item.impuesto_moneda).toFixed(4)"
                      >
                        <template v-slot:append-outer>
                          <TooltipInfo
                            :mensaje="`Otros impuesto se toman en cuenta para el costo del producto. Este impuesto se calcula por unidad`"
                          />
                        </template>
                      </v-text-field>
                    </td>
                    <td v-if="header.find(it => it.nombre == 'Descuento' && it.visible == true)">
                      <v-text-field
                        flat
                        solo
                        class="custom-text-field"
                        @keyup="changeCalcular('descuento', item)"
                         
                        v-model="item.descuento"
                        suffix="%"
                        type="number"
                        hide-details
                        @focus="$event.target.select()"
                      ></v-text-field>
                    </td>
                    <td v-show="header.find(it => it.nombre == 'Iva' && it.visible == true)">
                      <v-text-field
                        flat
                        solo
                        class="custom-text-field"
                        @keyup="changeCalcular('iva', item)"
                        
                        v-model="item.iva"
                        type="number"
                        suffix="%"
                        hide-details
                        @focus="$event.target.select()"
                      ></v-text-field>
                    </td>
                    <td v-show="header.find(it => it.nombre == 'Total' && it.visible == true)">
                      <v-text-field
                        flat
                        solo
                        class="custom-text-field"
                        hide-details
                        @keyup="changeCalcular('costo_total', item)"
                         
                        v-model="item.costo_total_moneda"
                        type="number"
                        :suffix="monedaSimbolo()"
                        @focus="$event.target.select()"
                        @change="item.costo_total_moneda = Number(item.costo_total_moneda).toFixed(4)"
                      ></v-text-field>
                    </td>
                    <td v-show="header.find(it => it.nombre == 'Almacen' && it.visible == true)">
                      <SelectAlmacen
                        :label="''"
                        :add="false"
                        v-model="item.id_almacen"
                        :filtro="{ id_puesto: datos.id_puesto }"
                        :rules="reglas.requerido"
                      />
                    </td>
                    <td>
                      <v-btn icon @click="eliminarProdcuto(item)"
                        ><v-icon>{{ icons.mdiDelete }}</v-icon></v-btn
                      >
                    </td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr>
                    <td colspan="7">
                      <v-btn class="ma-2" :disabled="!datos.moneda" outlined color="indigo" @click="GetProductoDetalle()"> agregar producto </v-btn>
                    </td>
                  </tr>
                </tfoot>
              </template>
            </v-simple-table>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-text>
          <v-row>
            <v-col cols="8">
              Pagos

              <br />

              <v-list width="100%">
                <v-divider></v-divider>
                <template v-for="(item, index) in datos.pagos">
                  <v-list-item :key="index">
                    <v-list-item-avatar size="30">
                      <v-avatar
                        :color="item.cuenta_bancaria ? '' : 'primary'"
                        :class="item.cuenta_bancaria ? '' : 'v-avatar-light-bg white--text'"
                        size="32"
                      >
                        <v-img
                          v-if="item.cuenta_bancaria"
                          :src="config.api_imagenes + '/banco/img/' + item.cuenta_bancaria.banco.img_icon"
                        >
                        </v-img>
                        <span v-else> {{ item.moneda.simbolo }}</span>
                      </v-avatar>
                    </v-list-item-avatar>

                    <v-list-item-content class="p-0 m-0">
                      <v-list-item-subtitle v-if="indFecha" class="py-0 my-0">
                        <strong
                          ><small>{{ item.fecha }}</small></strong
                        >
                      </v-list-item-subtitle>
                      <v-list-item-subtitle class="py-0 my-0">{{ item.nombre }} </v-list-item-subtitle>

                      <v-list-item-subtitle class="py-0 my-0"
                        >{{ item.moneda.tipo_pago.find(i => i.id == item.tipo_pago).nombre }} {{ item.moneda.simbolo }}:
                        {{ item.monto_moneda }}
                      </v-list-item-subtitle>
                      <v-list-item-subtitle class="py-0 my-0" v-if="item.moneda.tasa != 1">
                        <small
                          >Monto {{ datos.moneda.simbolo }}: {{ item.monto_real }}/ tasa: {{ item.moneda.tasa }}</small
                        >
                      </v-list-item-subtitle>
                      <v-list-item-subtitle class="py-0 my-0" v-if="item.cuenta_bancaria">
                        <small
                          >{{ item.cuenta_bancaria.banco.nombre }} | {{ item.cuenta_bancaria.numero_cuenta }}</small
                        >
                      </v-list-item-subtitle>
                      <v-list-item-subtitle class="py-0 my-0" v-if="item.referencia != ''"
                        ><small> Ref: {{ item.referencia }}</small>
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider :key="index"></v-divider>
                </template>
              </v-list>

              <div class="text-center"></div>
            </v-col>
            <v-col cols="4">
              <v-simple-table dense>
                <template v-slot:default>
                  <tbody>
                    <tr>
                      <td colspan="2"><strong>Totales</strong></td>
                    </tr>
                    <tr>
                      <td>Sub Total</td>
                      <td class="text-right">
                        {{ monedaSimbolo() }} {{ funciones.formatoNumeric(datos.sub_total_moneda, 4) }}
                      </td>
                    </tr>
                    <tr>
                      <td>Impuesto</td>
                      <td class="text-right">
                        {{ monedaSimbolo() }} {{ funciones.formatoNumeric(datos.impuesto_moneda, 4) }}
                      </td>
                    </tr>
                    <tr>
                      <td>IVA</td>
                      <td class="text-right">
                        {{ monedaSimbolo() }} {{ funciones.formatoNumeric(datos.iva_moneda, 4) }}
                      </td>
                    </tr>
                    <tr>
                      <td>Descuento</td>
                      <td class="text-right">
                        {{ monedaSimbolo() }} {{ funciones.formatoNumeric(datos.descuento_moneda, 4) }}
                      </td>
                    </tr>

                    <tr>
                      <td><h3>Total</h3></td>
                      <td class="text-right">
                        <h3>{{ monedaSimbolo() }} {{ funciones.formatoNumeric(datos.total_moneda, 4) }}</h3>
                      </td>
                    </tr>
                    <tr>
                      <td colspan="2"></td>
                    </tr>
                    <tr>
                      <td><h3>Total Real</h3></td>
                      <td class="text-right">
                        <h3>$ {{ funciones.formatoNumeric(datos.total, 4) }}</h3>
                      </td>
                    </tr>
                    <tr>
                      <td><h3>Total Pago</h3></td>
                      <td class="text-right">
                        <h3>$ {{ funciones.formatoNumeric(datos.total_pago, 4) }}</h3>
                      </td>
                    </tr>
                    <tr>
                      <td><h3>Resta</h3></td>
                      <td class="text-right">
                        <h3>$ {{ funciones.formatoNumeric(datos.total - datos.total_pago, 4) }}</h3>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
          </v-row>
        </v-card-text>
      </div>
    </v-sheet>
  </div>
</template>
<script>
import { onBeforeMount, ref } from '@vue/composition-api'
import store from '@/store'
import goTo from 'vuetify/lib/services/goto'
import BuscarProveedor from '../../proveedor/componentes/BuscarProveedor.vue'
import DatePicker from '@/components/DatePicker.vue'
import BuscarProducto from '@/views/sistema/inventario/producto/componentes/BuscarProducto.vue'
import BuscarProducto2 from '@/views/sistema/inventario/producto/componentes/BuscarProductoJson.vue'
import SelectEstado from '@/views/sistema/administracion/archivo/estado/componentes/Select.vue'
import SelectAlmacen from '@/views/sistema/inventario/maestro/almacen/componentes/Select.vue'
import SelectAlmacenJson from '@/views/sistema/inventario/maestro/almacen/componentes/SelectJson.vue'
import funciones from '@/funciones/funciones'
import TooltipInfo from '@/components/TooltipInfo.vue'
import SelectGrupoNegocio from '@/components/SelectGrupoNegocioValor.vue'
import SelectPuesto from '@/views/sistema/administracion/archivo/puesto/components/SelectPuesto2.vue'
import {
  mdiPackageVariantClosed,
  mdiInformation,
  mdiDelete,
  mdiPencil,
  mdiArrowUpThick,
  mdiEqual,
  mdiArrowDownThick,
  mdiCached,
  mdiArrowLeftThick,
  mdiTune,
} from '@mdi/js'
import CompraServices from '@/api/servicios/inventario/CompraServices'
import ViewInfoCompraProducto from './ViewInfoCompraProducto.vue'
import ModalPagos from './ModalPagos.vue'
import MaestroService from '@/api/servicios/MaestroService'
import config from '@/api/config'
import MonedaSelect from '@/components/SelectMonedaJson.vue'
export default {
  watch: {
    'datos.tasa': function (_new, _old) {
      if (_new != _old) {
        if   (!this.checkTasa ){
          this.recalcularDetalle()
        }else{
          this.recalcularDetalleMoneda()
        }
      }
    },
    '$store.state.puestoSelect.id_puesto': function () {
      this.limpiar()
    },
    'datos.moneda': function (_new, _old) {
      if (this.datos.moneda.tasa) {
        if (_new.id != _old?.id && (_old != null ||this.datos.id <0 )) {
          this.datos.tasa = this.datos.moneda.tasa
        }
         
          this.recalcularDetalle()
         
       
      }
      
    }, 
    'datos.id_almacen': function (_new, _old) {
       this.datos.productos.forEach(el=>{
         if (!(el.id_almacen > 0)){
          el.id_almacen = _new
         }
       })
    },
  },
  components: {
    BuscarProveedor,
    DatePicker,
    SelectEstado,
    SelectAlmacen,
    BuscarProducto,
    TooltipInfo,
    SelectAlmacenJson,
    ViewInfoCompraProducto,
    ModalPagos,
    MonedaSelect,
    SelectGrupoNegocio,
    SelectPuesto,
    BuscarProducto2,
  },
  setup(props, context) {
    const checkTasa = ref(true)
    const guardando = ref(false)
    const form = ref(null)
    const formDetalle = ref(null)
    const monedas = ref([])
    const validarAcciones = accion => {
      const valor = store.state.user.accion_modulo.includes(accion)

      return valor
    }
    const RefProductoDetalle = ref(null)
    const MonedaPais = ref({})
    const reglas = {
      requerido: [v => !!v || 'Es requerido'],
      numerico_requerido: [
        v => !!v || 'Es requerido',
        v => !isNaN(v) || 'No es numero valido',
        v => !(v == Infinity) || 'No es numero valido 2',
      ],
    }


    const recalcularDetalleMoneda = () => {
      datos.value.productos.forEach(item => {
        item.costo_unidad = (item.costo_unidad_moneda / (Number(datos.value.tasa) > 0 ? datos.value.tasa : 1)).toFixed(
          4,
        )
        item.impuesto = item.impuesto / (Number(datos.value.tasa) > 0 ? datos.value.tasa : 1)

        item.costo_total = ((Number(item.costo_unidad) + Number(item.impuesto)) * Number(item.cantidad)).toFixed(4)

        if (item.descuento / 100 > 0) {
          item.costo_total = (
            item.costo_total - Number(((item.descuento / 100) * item.costo_total).toFixed(4))
          ).toFixed(4)
        }
        if (item.iva / 100 > 0) {
          item.costo_total = ((1 + item.iva / 100) * item.costo_total).toFixed(4)
        }
      })

      totalizar()
    }

    const recalcularDetalle = () => {
      datos.value.productos.forEach(item => {
        item.costo_unidad_moneda = (item.costo_unidad * datos.value.tasa).toFixed(4)
        item.impuesto_moneda = item.impuesto * datos.value.tasa

        item.costo_total_moneda = (
          (Number(item.costo_unidad_moneda) + Number(item.impuesto_moneda)) *
          Number(item.cantidad)
        ).toFixed(4)

        if (item.descuento / 100 > 0) {
          item.costo_total_moneda = (
            item.costo_total_moneda - Number(((item.descuento / 100) * item.costo_total_moneda).toFixed(4))
          ).toFixed(4)
        }
        if (item.iva / 100 > 0) {
          item.costo_total_moneda = ((1 + item.iva / 100) * item.costo_total_moneda).toFixed(4)
        }
      })

      totalizar()
    }
    const header = ref([
      { nombre: 'Producto', visible: true },
      { nombre: 'Caja', visible: false },
      { nombre: 'Cantidad', visible: true },
      { nombre: 'Costo Und', visible: true },
      { nombre: 'Otro Impuesto', visible: false },
      { nombre: 'Descuento', visible: true },
      { nombre: 'Iva', visible: true },
      { nombre: 'Total', visible: true },
      { nombre: 'Almacen', visible: false },
    ])
    const changeProducto = item => {
      if ( datos.value.productos.filter(it => it.producto.id == item.producto.id).length <= 1 ) {
        console.log(item)
        if (item.costo_unidad == 0) {
          item.costo_unidad_moneda = (item.producto.costo * (datos.value.tasa ? datos.value.tasa : 1)).toFixed(4)
          item.costo_unidad = (item.producto.costo  ).toFixed(4)
        }
        item.producto = {
          id: item.producto.id,
          nombre: item.producto.nombre,
          costo: item.producto.costo,
          unidad_medida: item.producto.unidad_medida,
          ult_compra: item.producto.ult_compra,
          cuenta_contable: item.producto.cuenta_contable,
          ind_inventario: item.producto.ind_inventario,
          id_producto_tipo: item.producto.id_producto_tipo,
          und_caja: item.producto.und_caja,
        }
      }else{
        store.commit('setAlert', {
            message: 'Ya existe el producto en la factura',
            type: 'warning',
          })
        datos.value.productos = datos.value.productos.filter(it => it.id != item.id)
      }
    }
    const monedaSimbolo = () => {
      let simbolo = '$'
      if (datos.value.moneda) {
        simbolo = datos.value.moneda.simbolo
      }
      return simbolo
    }
    const monedaValor = _dato => {
      let valor = _dato
      if (datos.value.moneda) {
        valor = valor * datos.value.tasa
      }
      return valor
    }
    const monedaValorReal = _dato => {
      let valor = _dato
      if (datos.value.moneda) {
        valor = valor / datos.value.tasa
      }
      return valor
    }
    const GetPagos = _datos => {
      datos.value.pagos = _datos
      TotalizarPago()
    }
    const datosInit = {
      id_empleado: null,
      id: -1,
      proveedor: null,
      numero_factura: '',
      numero_control: '',
      almacen: null,
      id_almacen: null,
      empleado: '',
      id_puesto: null,
      puesto: null,
      id_grupo_negocio: 1,
      fecha_factura: null,
      fecha_recepcion: null,
      productos: [],
      sub_total: 0,
      total: 0,
      impuesto: 0,
      descuento: 0,
      iva: 0,
      estado: 33,
      estado_pago: null,
      observacion: '',
      total_pago: 0,
      pagos: [],
      moneda: null,
      tasa: null,
      id_moneda: 1,
      sub_total_moneda: 0,
      impuesto_moneda: 0,
      iva_moneda: 0,
      descuento_moneda: 0,
      total_moneda: 0,
    }
    const itemAddInit = {
      id: -1,
      cantidad: '0',
      costo_unidad: '0',
      costo_total: 0,
      cajas: '0',
      cajas_unidad: '0',
      descuento: '0',
      iva: '0',
      impuesto: '0',
      almacen: { id: null },
      producto: {},
    }
    const datos = ref(
      JSON.parse(
        JSON.stringify({
          ...datosInit,
          id_puesto: store.state.puestoSelect.id_puesto,
          id_empleado: store.state.user.id_empleado,
        }),
      ),
    )
    const TotalizarPago = () => {
      datos.value.total_pago = 0
      datos.value.pagos?.forEach(item => {
        datos.value.total_pago = datos.value.total_pago + Number(item.monto_real)
      })
    }

    const ResetPagos = () => {
      datos.value.pagos = []
      datos.value.total_pago = 0
    }
    const IrALista = () => {
      context.emit('IrALista', {})
    }

    const BuscarProveedorRef = ref(null)
    const limpiar = () => {
      datos.value = JSON.parse(
        JSON.stringify({
          ...datosInit,
          id_puesto: store.state.puestoSelect.id_puesto,
          id_empleado: store.state.user.id_empleado,
        }),
      )
      itemAdd.value = JSON.parse(JSON.stringify(itemAddInit))
      BuscarProveedorRef.value.model = null
      form.value.resetValidation()
    }
    var editando = false
    const editarProducto = async item => {
      editando = true
      var prod = datos.value.productos.find(ele => ele.producto.id == item.producto.id)
      await RefProductoDetalle.value.asignarPagoducto(prod.producto)
      itemAdd.value = JSON.parse(
        JSON.stringify({
          ...prod,
          costo_unidad: monedaValor(prod.costo_unidad).toString(),
          costo_total: monedaValor(prod.costo_total).toString(),
          impuesto: monedaValor(prod.impuesto).toString(),
          iva: prod.impuesto.toString(),
          descuento: prod.descuento.toString(),
        }),
      )
      datos.value.productos = datos.value.productos.filter(ele => ele.producto.id != item.producto.id)
      totalizar()
    }
    const eliminarProdcuto = item => {
      datos.value.productos = datos.value.productos.filter(ele => ele.id != item.id)
      totalizar()
    }

    const contador = ref(-5000)
    const constadorNew = () => {
      contador.value += 1
      return contador.value
    }
    const itemAdd = ref(JSON.parse(JSON.stringify(itemAddInit)))
    const cargandos = ref(false)

    const GetProductoDetalle = item => {
      datos.value.productos.push({
        id: constadorNew(),
        cantidad: '0',
        costo_unidad_moneda: '0',
        costo_unidad: '0',
        costo_total: 0,
        costo_total_moneda: 0,
        cajas: '0',
        cajas_unidad: 0,
        descuento: '0',
        iva: '0',
        impuesto: '0',
        impuesto_moneda: '0',
        id_almacen: datos.value.id_almacen,
        almacen: { id: datos.value.id_almacen },
        producto: null,
      })
    }

    onBeforeMount(() => {
      consultarMonedas()
    })
    const CargarEditar = item => {
      if (item > 0) {
        try {
          CompraServices.productoCompraListarPaginado({
            id: item,
          })
            .then(response => {
              console.log(response)
              if (response.data.mensaje == 'BUSQUEDA_EXITOSA') {
                datos.value = response.data.datos.datos[0]
                datos.value = { ...datos.value, almacen: datos.value.almacen.id }
                BuscarProveedorRef.value.asignarProveedor(datos.value.proveedor)
                totalizar()
                TotalizarPago()
                goTo(0)
              }
            })
            .catch(error => {
              console.log(error)
            })
            .finally(() => {})
        } catch (error) {}
      } else {
        limpiar()
      }
    }
    const validarForm = () => {
      let val = form.value?.validate()

      if (!(datos.value.productos.length > 0)) {
        val = false
      }

      return val
    }

    const validarFormDetalle = () => {
      let val = formDetalle.value?.validate()
      if (!val) {
        store.commit('setAlert', {
          message: 'Verifique los productos que no esten vacio',
          type: 'warning',
        })
      }

      datos.value.productos.forEach(it => {
        if (Number(it.cantidad) <= 0) {
          val = false
          store.commit('setAlert', {
            message: 'Las cantidades deben ser mayor que 0',
            type: 'warning',
          })
        }
        if (Number(it.costo_total_moneda) <= 0) {
          val = false
          store.commit('setAlert', {
            message: 'Los totales deben ser mayor que 0',
            type: 'warning',
          })
        }
        if (Number(it.descuento) < 0) {
          val = false
          store.commit('setAlert', {
            message: 'Los descuentos no deben ser menor que 0',
            type: 'warning',
          })
        }
        if (Number(it.iva) < 0) {
          val = false
          store.commit('setAlert', {
            message: 'El iva no deben ser menor que 0',
            type: 'warning',
          })
        }
        if (Number(it.impuesto_moneda) < 0) {
          val = false
          store.commit('setAlert', {
            message: 'Los impuestis no deben ser menor que 0',
            type: 'warning',
          })
        }

        if (!(it.id_almacen > 0)) {
          val = false
          store.commit('setAlert', {
            message: 'Verifique la seleccion de almacen de los productos',
            type: 'warning',
          })
        }
      })

      return val
    }

    const totalizar = () => {
      datos.value.sub_total = 0
      datos.value.impuesto = 0
      datos.value.iva = 0
      datos.value.descuento = 0
      datos.value.total = 0

      datos.value.sub_total_moneda = 0
      datos.value.impuesto_moneda = 0
      datos.value.iva_moneda = 0
      datos.value.descuento_moneda = 0
      datos.value.total_moneda = 0

      datos.value.productos.forEach(element => {
        datos.value.sub_total += Number(element.cantidad) * Number(element.costo_unidad)
        datos.value.impuesto += Number(element.cantidad) * Number(element.impuesto)

        datos.value.iva += Number(
          (element.iva / 100) *
            (element.cantidad * (Number(element.costo_unidad) + Number(element.impuesto)) -
              (element.descuento / 100) *
                (element.cantidad * (Number(element.costo_unidad) + Number(element.impuesto)))),
        )

        datos.value.descuento += Number(
          (element.descuento / 100) * (element.cantidad * (Number(element.costo_unidad) + Number(element.impuesto))),
        )
        datos.value.total += Number(element.costo_total)
      })

      datos.value.productos.forEach(element => {
        datos.value.sub_total_moneda += Number(element.cantidad) * Number(element.costo_unidad_moneda)
        datos.value.impuesto_moneda += Number(element.cantidad) * Number(element.impuesto_moneda)

        datos.value.iva_moneda += Number(
          (element.iva / 100) *
            (element.cantidad * (Number(element.costo_unidad_moneda) + Number(element.impuesto_moneda)) -
              (element.descuento / 100) *
                (element.cantidad * (Number(element.costo_unidad_moneda) + Number(element.impuesto_moneda)))),
        )

        datos.value.descuento_moneda += Number(
          (element.descuento / 100) *
            (element.cantidad * (Number(element.costo_unidad_moneda) + Number(element.impuesto_moneda))),
        )
        datos.value.total_moneda += Number(element.costo_total_moneda)
      })
    }

    const agregarDetalle = () => {
      if (validarFormDetalle()) {
        console.log(
          'agregarDetalle',
          datos.value.productos.find(item => item.producto.id == itemAdd.value.producto.id),
        )
        if (datos.value.productos.find(item => item.producto.id == itemAdd.value.producto.id) == undefined) {
          datos.value.productos.push({
            ...itemAdd.value,
            costo_unidad: monedaValorReal(itemAdd.value.costo_unidad),
            costo_total: monedaValorReal(itemAdd.value.costo_total),
            impuesto: monedaValorReal(itemAdd.value.impuesto),
          })
          itemAdd.value = JSON.parse(JSON.stringify(itemAddInit))
          RefProductoDetalle.value.model = null
          formDetalle.value?.resetValidation()
          totalizar()
        } else {
          store.commit('setAlert', {
            message: 'Ya existe el producto en la factura',
            type: 'warning',
          })
        }
      }
    }

    const ActualizarLista = item => {
      console.log(item)
      context.emit('GetActualizarLista', { ...item })
    }

    const changeCalcular = (tipo, item) => {
      switch (tipo) {
        case 'caja':
          if (item.cajas_unidad > 0) {
            item.cantidad = item.cajas * item.cajas_unidad
          } else {
            item.cantidad = 0
          }

          item.costo_unidad = Number(item.costo_unidad_moneda) / Number(datos.value.tasa)
          item.impuesto = Number(item.impuesto_moneda) / Number(datos.value.tasa)
          item.costo_total = ((Number(item.costo_unidad) + Number(item.impuesto)) * Number(item.cantidad)).toFixed(4)
          item.costo_total_moneda = (
            (Number(item.costo_unidad_moneda) + Number(item.impuesto_moneda)) *
            Number(item.cantidad)
          ).toFixed(4)

          if (item.descuento / 100 > 0) {
            item.costo_total = (
              item.costo_total - Number(((item.descuento / 100) * item.costo_total).toFixed(4))
            ).toFixed(4)
            item.costo_total_moneda = (
              item.costo_total_moneda - Number(((item.descuento / 100) * item.costo_total_moneda).toFixed(4))
            ).toFixed(4)
          }
          if (item.iva / 100 > 0) {
            item.costo_total = ((1 + item.iva / 100) * item.costo_total).toFixed(4)
            item.costo_total_moneda = ((1 + item.iva / 100) * item.costo_total_moneda).toFixed(4)
          }

          break

        case 'cantidad':
          if (item.cajas_unidad > 0) {
            item.cajas = item.cantidad / item.cajas_unidad
          } else {
            item.cajas = 0
          }

          item.costo_unidad = Number(item.costo_unidad_moneda) / Number(datos.value.tasa)
          item.impuesto = Number(item.impuesto_moneda) / Number(datos.value.tasa)
          item.costo_total = ((Number(item.costo_unidad) + Number(item.impuesto)) * Number(item.cantidad)).toFixed(4)
          item.costo_total_moneda = (
            (Number(item.costo_unidad_moneda) + Number(item.impuesto_moneda)) *
            Number(item.cantidad)
          ).toFixed(4)

          if (item.descuento / 100 > 0) {
            item.costo_total = (
              item.costo_total - Number(((item.descuento / 100) * item.costo_total).toFixed(4))
            ).toFixed(4)
            item.costo_total_moneda = (
              item.costo_total_moneda - Number(((item.descuento / 100) * item.costo_total_moneda).toFixed(4))
            ).toFixed(4)
          }
          if (item.iva / 100 > 0) {
            item.costo_total = ((1 + item.iva / 100) * item.costo_total).toFixed(4)
            item.costo_total_moneda = ((1 + item.iva / 100) * item.costo_total_moneda).toFixed(4)
          }

          break

        case 'costo':
          item.costo_unidad = Number(item.costo_unidad_moneda) / Number(datos.value.tasa)
          item.impuesto = Number(item.impuesto_moneda) / Number(datos.value.tasa)
          item.costo_total = ((Number(item.costo_unidad) + Number(item.impuesto)) * Number(item.cantidad)).toFixed(4)
          item.costo_total_moneda = (
            (Number(item.costo_unidad_moneda) + Number(item.impuesto_moneda)) *
            Number(item.cantidad)
          ).toFixed(4)

          if (item.descuento / 100 > 0) {
            item.costo_total = (
              item.costo_total - Number(((item.descuento / 100) * item.costo_total).toFixed(4))
            ).toFixed(4)
            item.costo_total_moneda = (
              item.costo_total_moneda - Number(((item.descuento / 100) * item.costo_total_moneda).toFixed(4))
            ).toFixed(4)
          }
          if (item.iva / 100 > 0) {
            item.costo_total = ((1 + item.iva / 100) * item.costo_total).toFixed(4)
            item.costo_total_moneda = ((1 + item.iva / 100) * item.costo_total_moneda).toFixed(4)
          }

          break

        case 'impuesto':
          item.costo_unidad = Number(item.costo_unidad_moneda) / Number(datos.value.tasa)
          item.impuesto = Number(item.impuesto_moneda) / Number(datos.value.tasa)
          item.costo_total = ((Number(item.costo_unidad) + Number(item.impuesto)) * Number(item.cantidad)).toFixed(4)
          item.costo_total_moneda = (
            (Number(item.costo_unidad_moneda) + Number(item.impuesto_moneda)) *
            Number(item.cantidad)
          ).toFixed(4)

          if (item.descuento / 100 > 0) {
            item.costo_total = (
              item.costo_total - Number(((item.descuento / 100) * item.costo_total).toFixed(4))
            ).toFixed(4)
            item.costo_total_moneda = (
              item.costo_total_moneda - Number(((item.descuento / 100) * item.costo_total_moneda).toFixed(4))
            ).toFixed(4)
          }
          if (item.iva / 100 > 0) {
            item.costo_total = ((1 + item.iva / 100) * item.costo_total).toFixed(4)
            item.costo_total_moneda = ((1 + item.iva / 100) * item.costo_total_moneda).toFixed(4)
          }

          break
        case 'descuento':
          item.costo_unidad = Number(item.costo_unidad_moneda) / Number(datos.value.tasa)
          item.impuesto = Number(item.impuesto_moneda) / Number(datos.value.tasa)
          item.costo_total = ((Number(item.costo_unidad) + Number(item.impuesto)) * Number(item.cantidad)).toFixed(4)
          item.costo_total_moneda = (
            (Number(item.costo_unidad_moneda) + Number(item.impuesto_moneda)) *
            Number(item.cantidad)
          ).toFixed(4)

          if (item.descuento / 100 > 0) {
            item.costo_total = (
              item.costo_total - Number(((item.descuento / 100) * item.costo_total).toFixed(4))
            ).toFixed(4)
            item.costo_total_moneda = (
              item.costo_total_moneda - Number(((item.descuento / 100) * item.costo_total_moneda).toFixed(4))
            ).toFixed(4)
          }
          if (item.iva / 100 > 0) {
            item.costo_total = ((1 + item.iva / 100) * item.costo_total).toFixed(4)
            item.costo_total_moneda = ((1 + item.iva / 100) * item.costo_total_moneda).toFixed(4)
          }

          break

        case 'iva':
          item.costo_unidad = Number(item.costo_unidad_moneda) / Number(datos.value.tasa)
          item.impuesto = Number(item.impuesto_moneda) / Number(datos.value.tasa)
          item.costo_total = ((Number(item.costo_unidad) + Number(item.impuesto)) * Number(item.cantidad)).toFixed(4)
          item.costo_total_moneda = (
            (Number(item.costo_unidad_moneda) + Number(item.impuesto_moneda)) *
            Number(item.cantidad)
          ).toFixed(4)

          if (item.descuento / 100 > 0) {
            item.costo_total = (
              item.costo_total - Number(((item.descuento / 100) * item.costo_total).toFixed(4))
            ).toFixed(4)
            item.costo_total_moneda = (
              item.costo_total_moneda - Number(((item.descuento / 100) * item.costo_total_moneda).toFixed(4))
            ).toFixed(4)
          }
          if (item.iva / 100 > 0) {
            item.costo_total = ((1 + item.iva / 100) * item.costo_total).toFixed(4)
            item.costo_total_moneda = ((1 + item.iva / 100) * item.costo_total_moneda).toFixed(4)
          }

          break
        case 'costo_total':
          item.costo_total = Number(item.costo_total_moneda) / Number(datos.value.tasa)
          item.impuesto = Number(item.impuesto_moneda) / Number(datos.value.tasa)

          var baseimponible = item.costo_total / (1 + item.iva / 100)
          var baseimponible_moneda = item.costo_total_moneda / (1 + item.iva / 100)

          var BaseDescuento = baseimponible / (1 - item.descuento / 100)
          var BaseDescuento_moneda = baseimponible_moneda / (1 - item.descuento / 100)

          var costo = BaseDescuento / item.cantidad - item.impuesto
          var costo_moneda = BaseDescuento_moneda / item.cantidad - item.impuesto_moneda

          item.costo_unidad = costo.toFixed(4)

          item.costo_unidad_moneda = costo_moneda.toFixed(4)

          break

        default:
          break
      }

      totalizar()
    }
    const GetProveedor = item => {
      console.log('GetProveedor', item)
      datos.value.proveedor = item
    }
    const consultarMonedas = () => {
      MaestroService.listarMonedas({ ind_pago_compras: true })
        .then(response => {
          if (response.data.mensaje == 'BUSQUEDA_EXITOSA') {
            monedas.value = response.data.datos
            MonedaPais.value = monedas.value.find(item => item.meneda_pais)
          }
        })
        .catch(err => {
          console.log(err)
        })
        .finally(() => {})
    }

    const Guardar = () => {
      guardando.value = true
      if (validarForm()) {
        if (validarFormDetalle()) {
        CompraServices.compraActualizar({
          ...datos.value,
          id_puesto: store.state.puestoSelect.id_puesto,
          id_moneda: datos.value.moneda.id,
          estado: datos.value.fecha_recepcion != null ? 33 : 29,
        })
          .then(response => {
            console.log(response)
            if (response.data.mensaje == 'FACTURA GUARDADA CON EXITO') {
              store.commit('setAlert', {
                message: response.data.mensaje,
                type: 'success',
              })
              ActualizarLista(response.data.datos)
              limpiar()
            } else {
              store.commit('setAlert', {
                message: `${response.data.mensaje}`,
                type: 'warning',
              })
            }
          })
          .catch(error => {
            console.log(error)
            store.commit('setAlert', {
              message: error,
              type: 'error',
              error: {
                ...error,
                response: error?.response?.data,
              },
              funcion: 'Guardar',
            })
          })
          .finally(() => {
            guardando.value = false
          })
        }
         
      } else {
        store.commit('setAlert', {
          message: 'Verifique que todos los datos estén completos',
          type: 'warning',
        })
      }
    }

    return {
      reglas,
      datos,
      CargarEditar,
      cargandos,
      limpiar,
      Guardar,
      form,
      ActualizarLista,
      BuscarProveedorRef,
      store,
      GetProductoDetalle,
      RefProductoDetalle,
      itemAdd,
      changeCalcular,
      formDetalle,
      agregarDetalle,
      icons: {
        mdiInformation,
        mdiDelete,
        mdiPencil,
        mdiArrowUpThick,
        mdiEqual,
        mdiArrowDownThick,
        mdiCached,
        mdiPackageVariantClosed,
        mdiArrowLeftThick,
        mdiTune,
      },
      eliminarProdcuto,
      editarProducto,
      GetProveedor,
      funciones,
      validarAcciones,
      monedas,
      GetPagos,
      ResetPagos,
      config,
      monedaSimbolo,
      monedaValor,
      monedaValorReal,
      IrALista,
      changeProducto,
      header,
      recalcularDetalle,
      recalcularDetalleMoneda,
      checkTasa
    }
  },
}
</script>
<style scoped>
.custom-text-field >>> input {
  text-align: right;
}
</style>