<template>
  <div>
    <div class="secondary">
      <v-container>
        <v-row>
          <v-bottom-navigation v-model="pago.moneda" class="pt-2" color="success" background-color="transparent" light>
            <v-btn
              @click="MonedaSelect(item)"
              v-for="(item, index) in monedasFilter()"
              :key="index"
              :value="item"
              color="white"
              class="mx-2"
            >
              <span>{{ item.nombre }}</span>
              {{ item.simbolo }}
            </v-btn>
          </v-bottom-navigation>
        </v-row>
        <v-row>
          <v-col class="text-center py-0 my-0">
            <v-btn
              class="ma-2"
              :color="item.id == pago.tipo_pago ? 'primary' : 'white'"
              small
              v-for="(item, index) in pago.moneda.tipo_pago"
              :key="'btn-' + index"
              @click="SelectFormaPago(item)"
            >
              {{ item.nombre }}  
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <v-row>
      <v-col cols="12" md="6" lg="8">
        <v-container>
          <v-row class="mt-2">
            <v-col cols="12" v-if="indFecha">
              <v-dialog ref="dialog" v-model="modal" :return-value.sync="pago.fecha" persistent width="290px">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    dense
                    outlined
                    v-model="pago.fecha"
                    label="Fecha"
                    hide-details
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="pago.fecha" scrollable locale="es-VE">
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="modal = false"> Cancel </v-btn>
                  <v-btn text color="primary" @click="$refs.dialog.save(pago.fecha)"> OK </v-btn>
                </v-date-picker>
              </v-dialog>
            </v-col>
            <v-col :md="tasaIgualDolar() ? '12' : '12'" class="my-0 py-0">
              <v-text-field
                v-model="monto_moneda"
                v-bind:label="'Monto moneda'"
                :prefix="pago.moneda.simbolo"
                outlined
                clearable
                persistentHint
                dense
                type="number"
                @keyup="calcularMontoDolar(1)"
                ref="montoMonedaRef"
                hide-details="auto"
              />
            </v-col>
            <v-col cols="12" class="my-0 py-0 mb-1" v-if="!tasaIgualDolar()">
              <v-checkbox v-model="tasafija" hide-details="auto" :label="`Tasa Fija`"></v-checkbox>
            </v-col>
            <v-col md="6" class="my-0 py-0" v-if="!tasaIgualDolar()">
              <v-text-field
                v-model="monto_real"
                :label="'Monto $'"
                prefix="$"
                outlined
                clearable
                persistentHint
                dense
                type="number"
                @keyup="calcularMontoDolar(2)"
                hide-details="auto"
              ></v-text-field>
            </v-col>

            <v-col md="6" class="my-0 py-0" v-if="!tasaIgualDolar()">
              <v-text-field
                v-model="tasaSelect"
                :label="'Tasa'"
                :prefix="pago.moneda.simbolo"
                outlined
                clearable
                persistentHint
                dense
                type="number"
                @keyup="calcularMontoDolar(3)"
                hide-details="auto"
              ></v-text-field>
            </v-col>

            <v-col cols="12" class="my-0 py-0 mt-2">
              <v-text-field
                class=""
                v-if="tipoPagoSelect().ind_referencia && !tipoPagoSelect().id_cuenta_bancaria"
                ref="ReferenciaRef"
                label="Referencia"
                placeholder="ref"
                outlined
                v-model="pago.referencia"
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" class="my-0 py-0">
              <v-btn color="primary" @click="AgregarPago()" small block> agregar </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
      <v-divider vertical></v-divider>
      <v-col
        cols="12"
        md="6"
        lg="4"
        class="mt-5 pt-5"
        :align-self="valorMaximo != -1 && valorMaximo !== undefined ? 'center' : 'center'"
      >
        <v-simple-table dense>
          <template>
            <tbody>
              <tr v-if="valorMaximo != -1 && valorMaximo !== undefined">
                <td class="text-center">
                  <strong>Total a pagar</strong>
                  <br />
                  $ {{ valorMaximo.toFixed(decimales) }}
                </td>
              </tr>
              <tr>
                <td class="text-center">
                  <strong>Pagos</strong>
                  <br />
                  $ {{ TotalPago().toFixed(decimales) }}
                </td>
              </tr>
              <tr v-if="valorMaximo != -1 && valorMaximo !== undefined">
                <td class="text-center">
                  <strong>Resta</strong>
                  <br />
                  $ {{ (valorMaximo - TotalPago()).toFixed(decimales) }}
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
    </v-row>
    <v-row>
      <v-list width="100%">
        <v-divider></v-divider>
        <template v-for="(item, index) in pagos">
          <v-list-item :key="index + 'pagos'">
            <v-list-item-avatar size="30">
              <v-avatar
                :color="item.cuenta_bancaria ? '' : 'primary'"
                :class="item.cuenta_bancaria ? '' : 'v-avatar-light-bg white--text'"
                size="32"
              >
                <v-img
                  v-if="item.cuenta_bancaria"
                  :src="config.api_imagenes + '/banco/img/' + item.cuenta_bancaria.banco.img_icon"
                >
                </v-img>
                <span v-else> {{ item.moneda.simbolo }}</span>
              </v-avatar>
            </v-list-item-avatar>

            <v-list-item-content class="p-0 m-0">
              <v-list-item-subtitle v-if="indFecha" class="py-0 my-0">
                <strong
                  ><small>{{ item.fecha }}</small></strong
                >
              </v-list-item-subtitle>
              <v-list-item-subtitle class="py-0 my-0">{{ item.nombre }} </v-list-item-subtitle>

              <v-list-item-subtitle class="py-0 my-0"
                >{{ item.moneda.tipo_pago.find(i => i.id == item.tipo_pago).nombre }} {{ item.moneda.simbolo }}:
                {{ item.monto_moneda }}
              </v-list-item-subtitle>
              <v-list-item-subtitle class="py-0 my-0" v-if="item.moneda.tasa != 1">
                <small>Monto $: {{ item.monto_real }}/ tasa: {{ item.moneda.tasa }}</small>
              </v-list-item-subtitle>
              <v-list-item-subtitle class="py-0 my-0" v-if="item.cuenta_bancaria">
                <small>{{ item.cuenta_bancaria.banco.nombre }} | {{ item.cuenta_bancaria.numero_cuenta }}</small>
              </v-list-item-subtitle>
              <v-list-item-subtitle class="py-0 my-0" v-if="item.referencia != ''"
                ><small> Ref: {{ item.referencia }}</small>
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-btn icon>
                <v-icon @click="EliminarPago(item)" color="grey lighten-1">{{ mdiCloseCircle }} </v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
          <v-divider :key="index"></v-divider>
        </template>
      </v-list>
    </v-row>
    <FormaPagoPuntoVenta ref="FormaPagoPuntoVentaRef" />
  </div>
</template>
<script>
import { ref, onBeforeMount, onBeforeUpdate, watch } from '@vue/composition-api'
import { mdiCash, mdiCloseCircle } from '@mdi/js'
import FormaPagoPuntoVenta from './FormaPagoCuentasBancarias.vue'
import store from '@/store'
import config from '@/api/config'
import funciones from '@/funciones/funciones'
export default {
  props: {
    monedas: Array,
    pagosInt: Array,
    dialog: Boolean,
    indFecha: Boolean,
    valorMaximo: Number,
    decimales: {
      type: Number,
      default: 2,
    },
  },
  components: {
    FormaPagoPuntoVenta,
  },
  setup(props, context) {
    const MonedaPais = ref(props.monedas.filter(item => item.meneda_pais == true)[0])
    const modal = ref(false)
    const FormaPagoPuntoVentaRef = ref(null)
    const comanda_pagoINIT = {
      id: 0,
      moneda: { id: '', simbolo: '', tasa: 1 },
      tipo_pago: '',
      monto_moneda: '',
      monto: 0,
      referencia: '',
      monto_real: '',
      nuevo: true,
      fecha: new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10),
    }
    const tasaSelect = ref(1)
    const monedaSelect = ref(null)
    const formasPagos = ref([])
    const formaPagoSelect = ref()
    const montoDolar = ref()
    const montoMoneda = ref()
    const montoDolarRef = ref()
    const montoMonedaRef = ref()
    const pago = ref({ ...comanda_pagoINIT })
    const monto_moneda = ref('')
    const monto_real = ref('')
    const pagos = ref([])
    const contador = ref(-5000)

    const monedasFilter = () => {
      return props.monedas.filter(item => item.id != 4)
    }

    watch(props, () => {
      limpiar()
      console.log('pagos')
    })

    onBeforeMount(() => {
      limpiar()
      monedaSelect.value = props.monedas[0]
    })

    const limpiar = () => {
      pagos.value = JSON.parse(JSON.stringify(props.pagosInt))
      monto_moneda.value = ''
      monto_real.value = ''
      console.log(pagos.value)
      console.log('pagos')
      let min = -5000
      if (pagos.value.length > 0) {
        pagos.value.forEach(item => {
          if (item.id > min && item.id < 0) {
            min = item.id
          }
        })
      }
      contador.value = min
      MonedaPais.value = props.monedas.filter(item => item.meneda_pais == true)[0]
    }

    const constadorNew = () => {
      contador.value += 1
      return contador.value
    }

    const tasaIgualDolar = () => {
      if (!pago.value.moneda.tasa) {
        return true
      } else {
        if (pago.value.moneda.tasa == 1) {
          return true
        } else {
          return false
        }
      }
    }

    const guardarPagos = () => {
      context.emit('GetPagos', pagos.value)
    }

    const tasafija = ref(true)

    const calcularMontoDolar = valor => {
      if (tasafija.value) {
        if (valor == 1) {
          pago.value.monto_moneda = Number(monto_moneda.value)
          pago.value.monto_real = (Number(pago.value.monto_moneda) / Number(tasaSelect.value)).toFixed(props.decimales)
          monto_real.value = (Number(pago.value.monto_moneda) / Number(tasaSelect.value)).toFixed(props.decimales)
        } else {
          pago.value.monto_real = Number(monto_real.value)
          pago.value.monto_moneda = (Number(pago.value.monto_real) * Number(tasaSelect.value)).toFixed(props.decimales)
          monto_moneda.value = (Number(pago.value.monto_real) * Number(tasaSelect.value)).toFixed(props.decimales)
        }
      } else {
        if (valor == 1 || valor == 2) {
          pago.value.monto_moneda = Number(monto_moneda.value)
          pago.value.monto_real =  Number(monto_real.value)  
          tasaSelect.value = ( Number(monto_moneda.value)/Number(pago.value.monto_real) ).toFixed(props.decimales)
        } else {
          pago.value.monto_real = Number(monto_real.value)
          pago.value.monto_moneda = (Number(pago.value.monto_real) * Number(tasaSelect.value)).toFixed(props.decimales)
          monto_moneda.value = (Number(pago.value.monto_real) * Number(tasaSelect.value)).toFixed(props.decimales)
        }

      }
    }

    const MonedaSelect = item => {
      pago.value.monto_moneda = ''
      pago.value.monto_real = ''
      pago.value.referencia = ''
      pago.value.tipo_pago = item.tipo_pago[0].id

      monto_moneda.value = ''
      monto_real.value = ''
      montoMonedaRef.value.focus()
      tasaSelect.value = item.tasa
      tasafija.value = true
    }

    const TotalPago = () => {
      let total = 0

      pagos.value.forEach(element => {
        total += Number(element.monto_real)
      })

      return total
    }

    const tipoPagoSelect = () => {
      let valor = {}
      if (pago.value.moneda && pago.value.tipo_pago) {
        valor = pago.value.moneda.tipo_pago.find(it => it.id == pago.value.tipo_pago)
      }
      console.log(valor)
      return valor
    }
    clearInterval

    const AgregarPago = () => {
      if (pago.value.monto_moneda >= 0.01 && pago.value.tipo_pago > 0) {
        if (props.valorMaximo == -1 || props.valorMaximo == undefined) {
          if (tipoPagoSelect().ind_cuenta_bancaria) {
            FormaPagoPuntoVentaRef.value.abrir(pago.value, _dato => {
              pagos.value.push({ ..._dato, id: constadorNew(), moneda: { ..._dato.moneda, tasa: tasaSelect.value } })
              pago.value = { ...comanda_pagoINIT }
              console.log(_dato)
            })
          } else {
            pagos.value.push({
              ...pago.value,
              id: constadorNew(),
              moneda: { ...pago.value.moneda, tasa: tasaSelect.value },
            })
            pago.value = { ...comanda_pagoINIT }
            console.log(pagos.value)
          }
        } else {
          if (Number(funciones.roundTo(props.valorMaximo,props.decimales)) >= Number(funciones.roundTo(( Number(pago.value.monto_real) + TotalPago()) ,props.decimales))) {
            if (tipoPagoSelect().ind_cuenta_bancaria) {
              FormaPagoPuntoVentaRef.value.abrir(pago.value, _dato => {
                console.log(pagos.value)
                pagos.value.push({ ..._dato, id: constadorNew(), moneda: { ..._dato.moneda, tasa: tasaSelect.value } })
                pago.value = { ...comanda_pagoINIT }
                monto_moneda.value = ''
                monto_real.value = ''
              })
            } else {
              console.log(pagos.value)
              pagos.value.push({
                ...pago.value,
                id: constadorNew(),
                moneda: { ...pago.value.moneda, tasa: tasaSelect.value },
              })
              pago.value = { ...comanda_pagoINIT }
              monto_moneda.value = ''
              monto_real.value = ''
            }
          } else {
            store.commit('setAlert', {
              message: 'Excede el monto a pagar'+ funciones.roundTo(props.valorMaximo,props.decimales)     +' ' +  funciones.roundTo(( Number(pago.value.monto_real) + TotalPago()) ,props.decimales) ,
              type: 'warning',

            })
          }
        }
      } else {
        if ( !(pago.value.tipo_pago > 0)) {
          store.commit('setAlert', {
            message: 'Debe seleccionar un tipo de pago valido',
            type: 'warning',

          })
        }else if (pago.value.monto_moneda >= 0.01 ) {
          store.commit('setAlert', {
            message: 'Debe insertar un valor valido',
            type: 'warning',
          })
        } else {
          store.commit('setAlert', {
            message: `Debe insertar un valor valido    ${pago.value.monto_moneda}   ${pago.value.monto_moneda}`,
            type: 'warning',
          })
        }
      }
    }

    const EliminarPago = item => {
      pagos.value = pagos.value.filter(it => it.id != item.id)
    }

    const descripcionTasa = () => {
      return 'tasa'
    }
    /*
    watch(monto_moneda, () => {
      pago.value.monto_moneda = monto_moneda.value
      calcularMontoDolar(1)
    })

    watch(monto_real, () => {
      pago.value.monto_real = monto_real.value
      calcularMontoDolar(2)
    })*/
    /*
        watch(monedaSelect, () => {
            console.log("monedaSelect",monedaSelect.value)
            formaPagoSelect.value = null
            formasPagos.value = monedaSelect.value.tipo_pago
        })*/

    const SelectFormaPago = item => {
      pago.value.tipo_pago = item.id
      montoMonedaRef.value.focus()
      tasaSelect.value = pago.value.moneda.tasa
    }

    return {
      monedaSelect,
      mdiCash,
      mdiCloseCircle,
      formasPagos,
      SelectFormaPago,
      formaPagoSelect,
      montoDolar,
      montoMoneda,
      descripcionTasa,
      tasaIgualDolar,
      montoDolarRef,
      montoMonedaRef,
      pagos,
      pago,
      calcularMontoDolar,
      MonedaSelect,
      rules: {
        required: value => !!value || 'Required.',
        counter: value => value.length >= 0,
      },
      AgregarPago,
      EliminarPago,
      MonedaPais,
      monedasFilter,
      guardarPagos,
      limpiar,
      modal,
      TotalPago,
      monto_moneda,
      monto_real,
      FormaPagoPuntoVentaRef,
      tipoPagoSelect,
      tasaSelect,
      config,
      tasafija
    }
  },
}
</script>