<template>
  <div>

    <v-card-title v-if="datos.length > 0"  class="mt-4">Reportes</v-card-title>
    <v-divider v-if="datos.length > 0"  class="mb-4" ></v-divider>
    <v-row class="match-height">
      <v-col cols="12" sm="6" md="4" v-for="(item, index) in datos" :key="index">
       
          <v-card @click="irReporte(item)">
            <v-card-text class="pb-0 mb-0">
              <v-row>
                <v-col>
                  <v-card-title class="pl-0 pt-1"> {{ item.nombre }} </v-card-title>
                </v-col>
                <v-col cols="auto" class="my-0 py-0">
                  <v-icon size="70" class="mr-4">
                    {{ item.imgicon ? item.imgicon : icons.mdiChartBoxMultiple }}
                  </v-icon>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-text class="mt-0 pt-0">
              <p>{{ item.descripcion }}</p>
            </v-card-text>
          </v-card>
     
      </v-col>
    </v-row>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import {
  mdiPoll,
  mdiLabelVariantOutline,
  mdiCurrencyUsd,
  mdiHelpCircleOutline,
  mdiShopping,
  mdiCashRegister,
} from '@mdi/js'

import MaestroService from '@/api/servicios/MaestroService'
import { onBeforeMount, ref, watch } from '@vue/composition-api'
import router from '@/router'
import store from '@/store'
// demos

export default {
  props: {
    p_id_categoria: undefined,
    p_id_accion_modulo: undefined,
  },
  components: {},
  setup(props) {
    const mdiChartBoxMultiple =
      'M4 20H16V22H4C2.9 22 2 21.1 2 20V7H4M22 4V16C22 17.1 21.1 18 20 18H8C6.9 18 6 17.1 6 16V4C6 2.9 6.9 2 8 2H20C21.1 2 22 2.9 22 4M12 8H10V14H12M15 6H13V14H15M18 11H16V14H18Z'

    const datos = ref([])
    const categorias = ref([])
    const cargando = ref(false)
    const filtroText = ref('')
    const categoriaSelec = ref(null)
    onBeforeMount(() => {
      cargarReportes()
    })

    watch(props , ()=>{

    })


    const irReporte = item => {
      if (item.tipo_reporte == 1){
        router.push({ name: item.url })
      }else{
        router.push({ name: 'administracion-archivo-reportesview', params: { id: item.id } })
      }
     
      
    }

    const filtrarReportes = cat => {
      return datos.value
        .filter(it => it.id_categoria == cat)
        .filter(it => store.state.user.accion_modulo.includes(it.id_accion_modulo))
    }

    const CategoriaFind = _id => {
      return categorias.value.find(item => item.id == _id)
    }

    watch(categoriaSelec, () => {
      FitrarCategorias()
    })
    watch(filtroText, () => {
      FitrarCategorias()
    })

    const FitrarCategorias = () => {
      let _datos = datos.value.filter(
        item =>
          !categoriaSelec.value || categoriaSelec.value.length == 0 || categoriaSelec.value.includes(item.id_categoria),
      )
      _datos = _datos.filter(
        item => item.nombre.toUpperCase().includes(filtroText.value.toUpperCase()) || filtroText.value == '',
      )

      groupDataByCategoria(_datos)
    }

    const groupedCategorias = ref([])
    const groupDataByCategoria = _datos => {
      groupedCategorias.value = _datos.reduce((acc, cur) => {
        var val = cur.id_categoria
        var index = acc.findIndex(function (x) {
          return x.key == val
        })
        if (index < 0) {
          index =
            acc.push({
              key: val,
              items: [],
            }) - 1
        }
        acc[index].items.push(cur)
        return acc
      }, [])
    }
    const cargarReportes = () => {
      cargando.value = true
      MaestroService.reportesListar({
        id_categoria: props.p_id_categoria,
        id_accion_modulo: props.p_id_accion_modulo,
      })
        .then(response => {
          console.log(response)
          if (response.data.mensaje == 'BUSQUEDA_EXITOSA') {
            datos.value = response.data.datos
            categorias.value = response.data.categorias
          }
        })
        .catch(error => {
          console.log(error)
        })
        .finally(() => {
          cargando.value = false
        })
    }
    return {
      icons: {
        mdiHelpCircleOutline,
        mdiShopping,
        mdiCashRegister,
        mdiChartBoxMultiple,
      },
      cargarReportes,
      cargando,
      categorias,
      datos,
      irReporte,
      filtrarReportes,
      filtroText,
      categoriaSelec,
      groupedCategorias,
      CategoriaFind,
    }
  },
}
</script>
