 
<template>
  <div class="text-center">
    <v-dialog v-model="dialog" max-width="600">
      <v-card class="text-center" v-if="cargando">
        <v-card-text>
          <v-progress-circular indeterminate color="primary"></v-progress-circular>
          <p>Cargando</p>
        </v-card-text>
      </v-card>
      <v-card v-else>
        <v-card-text v-if="listaFavoritos().length > 0 && !cuenta_bancaria" class="text-center">
          <strong>FAVORITOS</strong>
        </v-card-text>
        <v-card-text v-if="listaFavoritos().length > 0 && !cuenta_bancaria">
          <v-row>
            <v-col cols="12" sm="6" v-for="(item, index) in listaFavoritos()" :key="index" class="mb-2">
              <v-card class="primary" @click="false == false">
                <v-row>
                  <v-col cols="auto" @click="cuenta_bancaria = { ...item, ind_favorito: true }">
                    <v-avatar
                      class="ml-2"
                      :color="item.cuenta_bancaria ? '' : 'primary'"
                      :class="item.cuenta_bancaria ? '' : 'v-avatar-light-bg white--text'"
                      size="32"
                    >
                      <v-img :src="config.api_imagenes + '/banco/img/' + item.banco.img_icon"> </v-img>
                    </v-avatar>
                  </v-col>
                  <v-col cols="6" @click="cuenta_bancaria = { ...item, ind_favorito: true }">
                    <strong class="white--text">{{ item.numero_cuenta }}</strong> <br />
                    <v-divider></v-divider>
                    <small class="white--text">{{ item.banco.nombre }}</small>
                  </v-col>
                  <v-col cols="auto">
                    <v-btn icon @click="CheckFavoritos(item.id)">
                      <v-icon v-if="Favoritos(item.id)" color="secondary">{{ icons.mdiHeart }}</v-icon>
                      <v-icon v-else> {{ icons.mdiHeartOutline }} </v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
          </v-row>
          <v-divider class="mt-6"></v-divider>
        </v-card-text>

        <v-card-text>
          <v-row>
            <v-col cols="auto" v-if="banco || cuenta_bancaria">
              <v-btn class="primary" x-small @click="irAtras()"> Atras </v-btn>
            </v-col>
            <v-col class="text-center">
              <strong v-if="!banco && !cuenta_bancaria">BANCO</strong>
              <strong v-if="banco && !cuenta_bancaria">PUNTO DE VENTA</strong>
              <strong v-if="cuenta_bancaria">REFERENCIAS</strong>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-text>
          <v-row v-if="!banco && !cuenta_bancaria">
            <v-col cols="12" sm="6" md="4" lg="3" v-for="(item, index) in datos" :key="index">
              <v-card class="primary" @click="banco = item" height="100">
                <v-img
                  height="100"
                  class="d-flex align-center text-center"
                  :src="config.api_imagenes + '/banco/img/' + item.img"
                >
                </v-img>
              </v-card>
            </v-col>
          </v-row>
          <div v-if="banco && !cuenta_bancaria">
            <v-row v-for="(datos, index) in groupedPuntoVenta" :key="index">
              <v-col cols="12">
                {{ datos.key }}
              </v-col>
              <v-col cols="12" sm="6" v-for="(item, index) in datos.items" :key="index" class="mb-2">
                <v-card class="primary" @click="false == false">
                  <v-row>
                    <v-col cols="auto" @click="selectPuntoVenta(item)">
                      <v-avatar
                        class="ml-2"
                        :color="item.cuenta_bancaria ? '' : 'primary'"
                        :class="item.cuenta_bancaria ? '' : 'v-avatar-light-bg white--text'"
                        size="32"
                      >
                        <v-img :src="config.api_imagenes + '/banco/img/' + banco.img_icon"> </v-img>
                      </v-avatar>
                    </v-col>
                    <v-col cols="6" @click="selectPuntoVenta(item)">
                      <strong class="white--text">{{ item.numero_cuenta }}</strong> <br />
                      <v-divider></v-divider>
                      <small class="white--text">{{ banco.nombre }}</small>
                    </v-col>
                    <v-col cols="auto">
                      <v-btn icon @click="CheckFavoritos(item.id)">
                        <v-icon v-if="Favoritos(item.id)" color="secondary">{{ icons.mdiHeart }}</v-icon>
                        <v-icon v-else> {{ icons.mdiHeartOutline }} </v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-card>
              </v-col>
            </v-row>
          </div>
          <v-row v-if="cuenta_bancaria">
            <v-col cols="12">
            <v-row>
              <v-col cols="auto"  >
                <v-avatar
                  class="ml-2"
                  :color="cuenta_bancaria.cuenta_bancaria ? '' : 'primary'"
                  :class="cuenta_bancaria.cuenta_bancaria ? '' : 'v-avatar-light-bg white--text'"
                  size="32"
                >
                  <v-img v-if=" cuenta_bancaria.banco.img_icon" :src="config.api_imagenes + '/banco/img/' + cuenta_bancaria.banco.img_icon"> </v-img>
                </v-avatar>
              </v-col>
              <v-col   >
                <strong  >{{ cuenta_bancaria.numero_cuenta }}</strong> <br />
                <v-divider></v-divider>
                <small  >{{ cuenta_bancaria.banco.nombre }}</small>
              </v-col> 
            </v-row>
            <v-divider></v-divider>
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-if="tipoPagoSelect().ind_referencia"
                hide-details="auto"
                dense
                outlined
                label="referencia"
                v-model="pago.referencia"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-textarea
                v-if="tipoPagoSelect().ind_descripcion"
                hide-details="auto"
                dense
                outlined
                label="Descripcion larga"
                v-model="pago.descripcion"
              ></v-textarea>
            </v-col>
          </v-row>
          <v-btn block v-if="cuenta_bancaria" class="primary mt-2" @click="agregar()">listo</v-btn>
        </v-card-text>
        <v-divider></v-divider>
      </v-card>
    </v-dialog>
  </div>
</template>

 
<script>
import { ref, watch } from '@vue/composition-api'
import config from '@/api/config'
import store from '@/store'
import CuentasService from '@/api/servicios/CuentasService'
import { mdiHeart, mdiHeartOutline } from '@mdi/js'
export default {
  setup() {
    const banco = ref(null)
    const cuenta_bancaria = ref(null)
    const datos = ref([ 
    ])
    const tipoPagoSelect = () => {
      let valor = {}
      if (pago.value.moneda && pago.value.tipo_pago) {
        valor = pago.value.moneda.tipo_pago.find(it => it.id == pago.value.tipo_pago)
      }
      console.log(valor)
      return valor
    }
    const irAtras = () => {
      if (cuenta_bancaria.value) {
        cuenta_bancaria.value = null
      } else if (banco.value && !cuenta_bancaria.value) {
        banco.value = null
      }
    }

    const Favoritos = item => {
      if (store.state.puntoVentaFavorito.find(it => it == item)) {
        return true
      } else {
        return false
      }
    }
    const CheckFavoritos = dato => {
      if (store.state.puntoVentaFavorito.find(it => it == dato)) {
        store.commit('deletePuntoVentaFavorito', dato)
      } else {
        store.commit('setPuntoVentaFavorito', dato)
      }
    }
    const listaFavoritos = () => {
      let lista = []
      datos.value.forEach(ban => {
        ban.cuenta_bancaria.forEach(pun => {
          if (store.state.puntoVentaFavorito.includes(pun.id)) {
            lista.push({
              ...pun,
              banco: {
                id: ban.id,
                codigo: ban.codigo,
                nombre: ban.nombre,
                img: ban.img,
                img_icon: ban.img_icon,
              },
            })
          }
        })
      })

      return lista
    }
    watch(banco, () => {
      if (banco.value) {
        groupDataByGrupoNegocio()
      } else {
        groupedPuntoVenta.value = null
      }
    })
    const dialog = ref(false)
    const referencia = ref('')
    const descripcion = ref('')
    let _funcion = null
    const pago = ref(null)
    const abrir = (_pago, funcion) => {
      banco.value = null
      dialog.value = true
      cuenta_bancaria.value = null
      _funcion = funcion
      pago.value = _pago
      cargarPuntosVentas()
    }
    const cargando = ref(false)
    const cargarPuntosVentas = () => {
      cargando.value = true
      CuentasService.cuentaBancariaMonedaListar({ id_moneda: pago.value.moneda.id })
        .then(response => {
          console.log(response)
          if (response.data.mensaje == 'BUSQUEDA_EXITOSA') {
            datos.value = response.data.datos
          }
        })
        .catch(error => {
          console.log(error)
        })
        .finally(() => {
          cargando.value = false
        })
    }
    const selectPuntoVenta  =(item)=>{
      cuenta_bancaria.value  =  {
            ...item,
            banco: {
              id: banco.value.id,
              codigo: banco.value.codigo,
              nombre: banco.value.nombre,
              img: banco.value.img,
              img_icon: banco.value.img_icon,
            },
          } 
    }

    const agregar = () => {
      if (!cuenta_bancaria.value.ind_favorito) {
        _funcion({
          ...pago.value,
          id_cuenta_bancaria: cuenta_bancaria.value.id,
          cuenta_bancaria: {
            ...cuenta_bancaria.value,
            banco: {
              id: banco.value.id,
              codigo: banco.value.codigo,
              nombre: banco.value.nombre,
              img: banco.value.img,
              img_icon: banco.value.img_icon,
            },
          },
        })
      } else {
        _funcion({
          ...pago.value,
          id_cuenta_bancaria: cuenta_bancaria.value.id,
          cuenta_bancaria: {
            ...cuenta_bancaria.value,
          },
        })
      }

      dialog.value = false
    }
    const groupedPuntoVenta = ref([])
    const groupDataByGrupoNegocio = () => {
      groupedPuntoVenta.value = banco.value.cuenta_bancaria.reduce((acc, cur) => {
        var val = cur.grupo_negocio_descripcion
        var index = acc.findIndex(function (x) {
          return x.key == val
        })
        if (index < 0) {
          index =
            acc.push({
              key: val,
              items: [],
            }) - 1
        }
        acc[index].items.push(cur)
        return acc
      }, [])
    }
    return {
      dialog,
      abrir,
      datos,
      banco,
      cuenta_bancaria,
      agregar,
      referencia,
      descripcion,
      pago,
      tipoPagoSelect,
      groupedPuntoVenta,
      cargarPuntosVentas,
      config,
      icons: {
        mdiHeart,
        mdiHeartOutline,
      },
      store,
      CheckFavoritos,
      Favoritos,
      listaFavoritos,
      irAtras,
      selectPuntoVenta,
      cargando
    }
  },
}
</script>