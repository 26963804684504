 
<template>
  <div>
    <v-btn x-small color="primary" dark @click="dialog = true"> Abrir </v-btn>

    <v-dialog v-model="dialog" max-width="600px">
      <v-card>
        <v-card-subtitle class="text-center justify-center py-6"> Nombre Producto </v-card-subtitle>

        <v-tabs v-model="tab" background-color="transparent" grow>
          <v-tab v-for="item in items" :key="item">
            {{ item }}
          </v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab">
          <v-tab-item>
            <v-card flat>
              <v-card-text>
                <v-simple-table dense>
                  <template v-slot:default>
                    <tbody>
                      <tr>
                        <td>Costo Maximo</td>
                        <td class="text-right">{{ generales.min_costo_unidad.toFixed(4) }} $</td>
                      </tr>
                      <tr>
                        <td>Costo Minimo</td>
                        <td class="text-right">{{ generales.max_costo_unidad.toFixed(4) }} $</td>
                      </tr>
                      <tr>
                        <td>Costo Promedio</td>
                        <td class="text-right">{{ generales.avg_costo_unidad.toFixed(4) }} $</td>
                      </tr>
                      <tr>
                        <td colspan="2"></td>
                      </tr>
                      <tr>
                        <td>Cantidad Maxima</td>
                        <td class="text-right">{{ generales.min_cantidad.toFixed(2) }} U</td>
                      </tr>
                      <tr>
                        <td>Cantidad Minima</td>
                        <td class="text-right">{{ generales.min_cantidad.toFixed(2) }} U</td>
                      </tr>
                      <tr>
                        <td>Cantidad Promedio</td>
                        <td class="text-right">{{ generales.max_cantidad.toFixed(2) }} U</td>
                      </tr>
                      <tr>
                        <td colspan="2"></td>
                      </tr>
                      <tr>
                        <td>Primera Compra</td>
                        <td class="text-right">{{ funciones.formatoFecha(generales.min_fecha, 1) }}</td>
                      </tr>
                      <tr>
                        <td>Ultima Compra</td>
                        <td class="text-right">{{ funciones.formatoFecha(generales.max_fecha) }}</td>
                      </tr>
                      <tr>
                        <td>Ultima Proveedor</td>
                        <td class="text-right">{{ compras[0].nombre }}</td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <v-tab-item>
            <v-card flat>
              <v-card-text>
                <v-simple-table height="300px">
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left">Numero</th>
                        <th class="text-left">Proveedor</th>
                        <th class="text-left">Cantidad</th>
                        <th class="text-left">Costo</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(item, index) in compras" :key="index">
                        <td>
                          <small>{{ item.numero_documento }}</small>
                        </td>
                        <td>
                          <small>{{ item.nombre }}</small>
                        </td>
                        <td>{{ item.cantidad }}</td>
                        <td>{{ item.costo_unidad }}</td>
                        <td>
                          <v-btn small icon @click="GetVer(item)">
                            <v-icon color="primary">{{ icons.mdiEye }}</v-icon>
                          </v-btn>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-card-text>
            </v-card>
          </v-tab-item>

          <v-tab-item>
            <v-card flat>
              <v-card-text>
                <apexchart type="line" height="350" :options="chartOptions" :series="series"></apexchart>
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs-items>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn small color="secondary" @click="dialog = false"> Cerrar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <ModalViewCompra :VerButton="false" ref="ModalViewCompraRef"></ModalViewCompra>
  </div>
</template>

<script>
import { ref } from '@vue/composition-api'
import funciones from '@/funciones/funciones'
import ModalViewCompra from './ModalViewCompra.vue'
import { mdiEye } from '@mdi/js'
export default {
  components: {
    ModalViewCompra,
  },
  setup() {
    const dialog = ref(false)
    const generales = ref({
      min_costo_unidad: 0.650667,
      max_costo_unidad: 2.165196,
      avg_costo_unidad: 1.2267871818181818,
      min_cantidad: 10.0,
      max_cantidad: 66.0,
      avg_cantidad: 33.3290909090909091,
      min_fecha: '2023-05-24T10:52:51.223869',
      max_fecha: '2023-12-06T10:05:27.58544',
    })
    const compras = ref([
      {
        id: 1152,
        numero_documento: 'COM-00-000703',
        costo_unidad: 1.75,
        cantidad: 20.0,
        documento: 'J-30399562-4',
        nombre: 'DISTRIBUIDORA FRUTAGRO C.A',
        fecha: '2023-12-06T10:05:27.58544',
        unidad: 'KG',
      },
      {
        id: 1141,
        numero_documento: 'COM-00-000692',
        costo_unidad: 2.165196,
        cantidad: 20.4,
        documento: 'J-502279972-5',
        nombre: 'CAJA CHICA',
        fecha: '2023-12-01T18:18:26.751579',
        unidad: 'KG',
      },
      {
        id: 1078,
        numero_documento: 'COM-00-000629',
        costo_unidad: 1.6,
        cantidad: 30.0,
        documento: 'J-40419670-6',
        nombre: 'DISTRIBUIDORA TRANSJULIN, C.A',
        fecha: '2023-11-17T12:59:50.400365',
        unidad: 'KG',
      },
      {
        id: 1021,
        numero_documento: 'COM-00-000572',
        costo_unidad: 1.5,
        cantidad: 20.0,
        documento: 'J-30399562-4',
        nombre: 'DISTRIBUIDORA FRUTAGRO C.A',
        fecha: '2023-10-31T09:56:41.198067',
        unidad: 'KG',
      },
      {
        id: 942,
        numero_documento: 'COM-00-000499',
        costo_unidad: 0.88,
        cantidad: 38.0,
        documento: 'J-40936134-9',
        nombre: 'DISTRIBUIDORA LUAL, C.A',
        fecha: '2023-10-17T16:20:01.374034',
        unidad: 'KG',
      },
      {
        id: 913,
        numero_documento: 'COM-00-000470',
        costo_unidad: 0.890455,
        cantidad: 66.0,
        documento: 'J-40936134-9',
        nombre: 'DISTRIBUIDORA LUAL, C.A',
        fecha: '2023-10-10T11:46:13.720761',
        unidad: 'KG',
      },
      {
        id: 842,
        numero_documento: 'COM-00-000401',
        costo_unidad: 1.3,
        cantidad: 34.0,
        documento: 'J-30399562-4',
        nombre: 'DISTRIBUIDORA FRUTAGRO C.A',
        fecha: '2023-09-22T10:50:48.392611',
        unidad: 'KG',
      },
      {
        id: 822,
        numero_documento: 'COM-00-000381',
        costo_unidad: 1.5,
        cantidad: 20.0,
        documento: 'J-30399562-4',
        nombre: 'DISTRIBUIDORA FRUTAGRO C.A',
        fecha: '2023-09-18T11:29:46.590062',
        unidad: 'KG',
      },
      {
        id: 796,
        numero_documento: 'COM-00-000355',
        costo_unidad: 1.5,
        cantidad: 20.0,
        documento: 'J-30399562-4',
        nombre: 'DISTRIBUIDORA FRUTAGRO C.A',
        fecha: '2023-09-11T11:58:37.629444',
        unidad: 'KG',
      },
      {
        id: 741,
        numero_documento: 'COM-00-000301',
        costo_unidad: 1.2,
        cantidad: 40.0,
        documento: 'J-40419670-6',
        nombre: 'DISTRIBUIDORA TRANSJULIN, C.A',
        fecha: '2023-08-30T12:22:52.495641',
        unidad: 'KG',
      },
    ])
    const series = [
      {
        name: "Desktops",
              data: [10, 41, 35, 51, 49, 62, 69, 91, 148]
      } 
    ]
    const chartOptions = {
        chart: {
              height: 350,
              type: 'line',
              zoom: {
                enabled: false
              }
            },
            dataLabels: {
              enabled: true
            },
            stroke: {
              curve: 'straight'
            },
            title: {
              text: 'Product Trends by Month',
              align: 'left'
            },
            grid: {
              row: {
                colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                opacity: 0.5
              },
            },
            xaxis: {
              categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep'],
            }
       
    }

    const ModalViewCompraRef = ref(null)
    const GetVer = item => {
      ModalViewCompraRef.value.abrir(item.id)
    }

    return {
      dialog,
      tab: null,
      items: ['Generales', 'Ultimas Compras', 'Grafica'],
      compras,
      generales,
      funciones,
      GetVer,
      ModalViewCompraRef,
      icons: {
        mdiEye,
      },
      series,
      chartOptions,
    }
  },
}
</script>